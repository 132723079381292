<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ titleDetalle }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="itemsDetalleActualizacionTablaFac"
          item-key="procAumTablaFacDetaId"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip top max-width="35%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="py-4 fontsize"
              v-bind="attrs"
              small
              outlined
              :disabled="itemsDetalleActualizacionTablaFac.length < 1"
              @click="exportExcel"
            >
              Exportar detalle
            </v-btn>
          </template>
        </v-tooltip>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import helpersExcel from "@/utils/helpers/importExportExcel";
import enums from "@/utils/enums/index.js";

export default {
  name: "VerDetalleActualizacionTablaFacturacion",
  props: {
    procAumTablaFacId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    search: null,
    searchIcon: enums.icons.SEARCH,
    titleDetalle: "Detalle de actualización",
    isLoading: false,
    headers: [
      {
        text: "Tabla anterior",
        value: "tablaAnterior",
        sortable: false
      },
      {
        text: "Vigencia anterior",
        value: "vigenciaAnterior",
        sortable: false
      },
      {
        text: "Tabla nueva",
        value: "tablaNueva",
        sortable: false
      },
      {
        text: "Vigencia nueva",
        value: "vigencia",
        sortable: false
      }
    ],
    itemsDetalleActualizacionTablaFac: [],
    itemsDetalleExcel: []
  }),
  created() {
    this.loadDetalleTablaFac();
  },
  methods: {
    ...mapActions({
      getDetalleProceso: "prestadores/getDetalleProceso",
      getDetalleTablasAfectadasxProceso:
        "prestadores/getDetalleTablasAfectadasxProceso",
      setAlert: "user/setAlert"
    }),
    async loadDetalleTablaFac() {
      this.isLoading = true;
      const response = await this.getDetalleProceso(this.procAumTablaFacId);
      this.itemsDetalleExcel = response;
      const responseDetalle = await this.getDetalleTablasAfectadasxProceso(
        this.procAumTablaFacId
      );
      this.itemsDetalleActualizacionTablaFac = responseDetalle;
      this.isLoading = false;
      if (this.itemsDetalleActualizacionTablaFac.length < 1) {
        this.setAlert({
          type: "warning",
          message: "El registro no posee actualización"
        });
      }
    },
    closeModal() {
      this.$emit("closeModalVerDetalleActualizacion");
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    exportExcel() {
      let result = [];
      this.itemsDetalleExcel.forEach(x =>
        result.push({
          ["Número de proceso"]: this.procAumTablaFacId,
          ["Origen"]: x.origen,
          ["Agente"]: x.agente,
          ["Prestador"]: x.prestador,
          ["Convenio"]: x.os,
          ["Plan"]: x.plan,
          ["Tabla anterior"]: x.tabAnterior,
          ["Vigencia anterior"]: x.vigenciaAnterior,
          ["Tabla nueva"]: x.tabNueva,
          ["Vigencia nueva"]: x.vigencia
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalle"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle de actualización de tabla de facturación"
      );
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
