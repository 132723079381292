<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Tablas de vigencia posterior</v-card-title
      >
      <v-data-table
        :headers="headers"
        :items="tablasVigPosterior"
        class="elevation-1"
        :loading="isLoading"
      >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-1"> </v-col>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "VerTablaVigenciaPosterior",
  props: {
    tablasVigPost: {
      type: Array,
      required: true,
      default: []
    }
  },
  data: () => ({
    isLoading: false,
    tablasVigPosterior: [],
    headers: [
      {
        text: "Tabla",
        value: "tabId",
        sortable: false
      },
      {
        text: "Nombre",
        value: "tabla",
        sortable: false
      },
      {
        text: "Agente",
        value: "agente",
        sortable: false
      },
      {
        text: "Prestador",
        value: "prestador",
        sortable: false
      },
      {
        text: "Convenio",
        value: "convenio",
        sortable: false
      },
      {
        text: "Tabla posterior",
        value: "tabIdPosterior",
        sortable: false
      },
      {
        text: "Nombre",
        value: "tablaPosterior",
        sortable: false
      },
      {
        text: "Plan",
        value: "plan",
        sortable: false
      },

      {
        text: "Vigencia posterior",
        value: "vigenciaPosterior",
        sortable: false
      }
    ],
    allowedActions: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setVerTablaVigPost();
  },
  methods: {
    async setVerTablaVigPost() {
      this.isLoading = true;
      this.tablasVigPosterior = this.tablasVigPost;
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
