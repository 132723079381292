<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">Tablas modificadas</v-card-title>
      <v-data-table
        :headers="headers"
        :items="tablasAnular"
        class="elevation-1"
        :loading="isLoading"
      >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="py-1"> </v-col>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        <v-btn color="primary" @click="confirmAnular">
          Anular de todas formas
        </v-btn>
      </v-card-actions>

      <v-dialog
        v-if="modalVerTablasVigPost"
        v-model="modalVerTablasVigPost"
        @keydown.esc="closeAndReload"
        max-width="60%"
        persistent
      >
        <VerTablaVigenciaPosterior
          :tablasVigPost="tablasVigPost"
          @closeAndReload="closeAndReload"
        ></VerTablaVigenciaPosterior>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import VerTablaVigenciaPosterior from "@/components/modules/prestadores/VerTablaVigenciaPosterior.vue";
export default {
  name: "VerTablasAnular",
  components: {
    VerTablaVigenciaPosterior
  },
  props: {
    idToAnular: {
      type: Number,
      required: true
    },

    tablasModificadas: {
      type: Array,
      required: false,
      default: []
    }
  },
  data: () => ({
    isLoading: false,
    tablasAnular: [],
    tablasVigPost: [],
    modalVerTablasVigPost: false,

    headers: [
      {
        text: "Tabla",
        value: "id",
        sortable: false
      },
      {
        text: "Nombre",
        value: "value",
        sortable: false
      }
    ],
    allowedActions: null,
    localConfirmValidaTablasModificadas: true
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setVerTablasAnular();
  },
  methods: {
    ...mapActions({
      anularProcesoActualizFactura: "prestadores/anularProcesoActualizFactura",
      setAlert: "user/setAlert"
    }),
    async setVerTablasAnular() {
      this.isLoading = true;

      this.tablasAnular = this.tablasModificadas;
      this.isLoading = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
      this.modalVerTablasVigPost = false;
    },
    closeAndReload() {
      this.modalVerTablasVigPost = false;
      this.$emit("closeAndReload");
    },
    async confirmAnular() {
      const data = await this.anularProcesoActualizFactura({
        procAumTablaFacId: this.idToAnular,
        validaTablasModificadas: false
      });
      const searchErrors = data;
      if (searchErrors.error == 2) {
        this.setAlert({
          type: "warning",
          message: searchErrors.mensaje
        });
        this.tablasVigPost = data.tablasVigenciaPosterior;
        this.modalVerTablasVigPost = true;
      } else {
        this.setAlert({
          type: "success",
          message: searchErrors.mensaje
        });
        this.$emit("closeAndReload");
        this.$emit("loadProcesos");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
